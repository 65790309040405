import {Component, OnInit} from '@angular/core';
import {environment} from "../../../environments/environment";
import {ActivatedRoute, Router} from "@angular/router";
import {StudentService} from "../../shared/services/student.service";
import {StorageService} from "../../shared/services/storage.service";

@Component({
  selector: 'ps-payment-response',
  templateUrl: './payment-reponse.component.html',
  styleUrls: ['./payment-reponse.component.scss']
})
export class PaymentReponseComponent implements OnInit {
  uniqueId: any;
  type: any;
  transactionType: any;
  paymentFor: any;
  environmentUrl: any = environment.appUrl;
  user: any;
  studentData: any;

  constructor(private activatedRoute: ActivatedRoute,
              private studentService: StudentService,
              private router: Router) {
    this.user = StorageService.getItem('self');
  }

  ngOnInit(): void {
    this.uniqueId = this.activatedRoute.snapshot.params['unique_id'];
    this.type = this.activatedRoute.snapshot.params['type'];
    this.paymentFor = this.activatedRoute.snapshot.params['payment_for'];
    if (this.type === 'success') {
      this.transactionType = 'SUCCESS';
    } else if (this.type === 'failed') {
      this.transactionType = 'FAILED';
    } else {
      this.transactionType = 'CANCELLED';
    }
    this.getStudentDetail();
  }

  getStudentDetail() {
    this.studentService.detail(this.user.user_id).subscribe(data => {
      if (data) {
        this.studentData = data;
      }
    });
  }

  goTo(url) {
    /*this.router.navigateByUrl(url);*/
  }

  openUrl(url) {
    window.open(url, '_blank');
  }
}
