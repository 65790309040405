<div class="authentication-main">
  <ng-template #content let-modal>
    <div id="modal">
      <div class="modal-header font-weight-bold">
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'FORGOT_PASSWORD'">Forgot Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'RESET_PASSWORD'">Reset Password</h4>
        <h4 class="modal-title font-weight-bold" *ngIf="modalType === 'USER_REGISTRATION_OTP'">User Registration OTP</h4>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="modalService.dismissAll('')"
        >
          ×
        </button>
      </div>
      <div class="modal-body" ngbAutoFocus>
        <div class="row" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <form class="form" novalidate [formGroup]="registrationOtpForm">
              <div class="row">
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                  <fieldset class="form-group">
                    <input class="form-control" type="number" formControlName="otp"
                           placeholder="Enter OTP">
                    <small *ngIf="submittedOtp && registrationOtpForm.get('otp').hasError('required')"
                           class="text-danger">
                      Field is required.
                    </small>
                  </fieldset>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'FORGOT_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="enrollmentVerifyForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <fieldset class="form-group">
                        <label>Enrollment No.</label>
                        <input class="form-control" type="text" formControlName="enrollment_no" placeholder="Enter Enrollment No.">
                        <small *ngIf="submittedEnrollmentNoVerify && enrollmentVerifyForm.get('enrollment_no').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </fieldset>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="modalType === 'RESET_PASSWORD'">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div class="row">
              <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <form class="form" novalidate [formGroup]="updatePasswordForm">
                  <div class="row">
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code Sent to</label>
                        <input class="form-control" formControlName="contact"/>
                        <small *ngIf="submitted && updatePasswordForm.get('contact').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Password
                          <i (click)="inputTypePassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypePassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypePassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypePassword" formControlName="password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label d-flex">
                          Confirm Password
                          <i (click)="inputTypeConfirmPassword = 'text'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'text')}"
                             class="fas fa-eye-slash d-flex align-items-center pl-1 cursor-pointer f_10"></i>
                          <i (click)="inputTypeConfirmPassword = 'password'"
                             [ngClass]="{'display-hidden': (inputTypeConfirmPassword === 'password')}"
                             class="fas fa-eye d-flex align-items-center pl-1  cursor-pointer f_10"></i>
                        </label>
                        <input class="form-control" [type]="inputTypeConfirmPassword" formControlName="confirm_password"/>
                        <small *ngIf="submitted && updatePasswordForm.get('confirm_password').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                    <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                      <div class="form-group">
                        <label class="col-form-label">Verification Code</label>
                        <input class="form-control" formControlName="otp" type="number"/>
                        <small *ngIf="submitted && updatePasswordForm.get('otp').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-1" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-right">
            <small>Resend OTP in - </small>
            <small class="text-danger mr-1"
                   *ngIf="timerSixtySeconds > 0">{{timerSixtySeconds}} sec.</small>
            <small class="text-primary cursor-pointer"
                   *ngIf="timerSixtySeconds === 0" (click)="resendOTP()">Resend OTP ?
            </small>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="row">
          <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12 text-center">
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="submitOtp()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'USER_REGISTRATION_OTP'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Submit
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="verifyEnrollmentNo()"
                    [disabled]="submittingOtp" *ngIf="modalType === 'FORGOT_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingOtp"></i>Verify
            </button>
            <button class="btn btn-primary btn-block btn-raised color-white mr-2 users-btn"
                    (click)="resetPassword()"
                    [disabled]="submittingPassword" *ngIf="modalType === 'RESET_PASSWORD'">
              <i class="fas fa-spinner fa-pulse mr-1" *ngIf="submittingPassword"></i>Reset
            </button>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  <div class="row">
    <div class="col-md-12">
      <div class="auth-innerright">
        <div class="authentication-box">
          <div class="mt-4">
            <div class="card-header" style="background: transparent; border-bottom: none ; margin-bottom: 20px">
              <div class="main-header-left text-center">
                <div class="logo-wrapper"><a href="javascript:void(0)" routerLink='/'>
                  <img src="assets/images/logo/logo2.png" alt="University Management System" style="height: 70px"></a>
                </div>
                <!--<div class="row mt-20px">
                  <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                    <h4 class="text-italic font-weight-bold">
                      <i class="fa-solid fa-sun fa-spin mt-5px mr-5px fa-3x text-danger"></i>
                    </h4>
                  </div>
                  <div class="col-xl-12 col-md-12 col-sm-12 col-xs-12">
                    <h2 class="text-italic font-weight-bold text-yellow">
                      "System is currently undergoing scheduled maintenance, we will be back soon"
                    </h2>
                  </div>
                </div>-->
              </div>
            </div>
            <div class="card-body px-0">
              <!--<div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
                  <h5 class="text-italic badge badge-pill pill-badge-warning" style="font-size: 22px !important;">System is currently undergoing maintenance and will resume service at 4:00 AM.</h5>
                </div>
              </div>-->
              <div class="cont text-center b-light" [ngClass]="{'s--signup': signup}">
                <div>
                  <form class="theme-form" [formGroup]="loginForm">
                    <h4>Sign In</h4>
                    <h6>Sign In to your account and start exploring</h6>
                    <div class="form-group text-left mt-30px">
                      <label class="col-form-label pt-0">Username</label>
                      <input class="form-control border-radius-0" type="text" placeholder="Enrollment No. / Email" formControlName="username"/>
                    </div>
                    <div class="form-group text-left">
                      <label class="col-form-label">Password</label>
                      <input class="form-control border-radius-0" type="password" placeholder="*******" formControlName="password"/>
                      <small class="text-primary">Use your Contact No. or DOB (in YYYY-MM-DD) format as password</small>
                    </div>
                    <div class="checkbox p-0 text-left">
                      <input id="checkbox1" type="checkbox">
                      <label for="checkbox1" class="f_12">
                        <small>Remember me</small>
                      </label>
                    </div>
                    <div class="form-group text-left text-info mt-55px">
                      <div class="row">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
                          <!--<small class="col-form-label f_12 cursor-pointer">Forgot Password ?</small>-->
                        </div>
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right">
                          <!--<small class="col-form-label f_12 cursor-pointer">Forgot Username ?</small>-->
                          <small class="col-form-label f_12 cursor-pointer" (click)="openForgotPasswordModal(content)">Forgot Password ?</small>
                        </div>
                      </div>
                    </div>
                    <div class="form-group form-row mt-3 mb-0">
                      <button class="btn btn-primary btn-block" type="submit" (click)="signIn()">Sign In</button>
                    </div>
                    <div class="social mt-3 d-block d-md-none height-150px">
                    </div>
                  </form>
                </div>
                <div class="sub-cont">
                  <div class="img">
                    <div class="img__text m--up">
                      <h2>New Student ?</h2>
                      <p>Register yourself and discover great amount of new opportunities !</p>
                    </div>
                    <div class="img__text m--in">
                      <h2>Already Registered ?</h2>
                      <p>Sign In to your account and start exploring !</p>
                    </div>
                    <div class="img__btn" (click)=toggle()>
                      <span class="m--up">Register</span>
                      <span class="m--in">Sign in</span>
                    </div>
                  </div>
                  <div>
                    <form class="theme-form text-left" [formGroup]="registrationForm">
                      <h4 class="text-center">Register Now</h4>
                      <h6 class="text-center">Enter your details and choose course</h6>
                      <div class="form-row mt-20px">
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="text" placeholder="Full Name" formControlName="name"/>
                            <small *ngIf="submitted && registrationForm.get('name').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="text" placeholder="Email" formControlName="email"/>
                            <small *ngIf="submitted && registrationForm.get('email').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                            <small *ngIf="submitted && registrationForm && registrationForm.controls && registrationForm.controls['email'] && registrationForm.controls['email'].errors && registrationForm.controls['email'].errors.pattern"
                                   class="text-danger">Invalid email format</small>
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input class="form-control border-radius-0" type="number" placeholder="Contact" formControlName="contact"/>
                            <small *ngIf="submitted && registrationForm.get('contact').hasError('required')"
                                   class="text-danger">
                              Field is required.
                            </small>
                            <small *ngIf="submitted && registrationForm && registrationForm.controls && registrationForm.controls['contact'] && registrationForm.controls['contact'].errors && registrationForm.controls['contact'].errors.pattern"
                                   class="text-danger">Invalid contact format</small>
                          </div>
                        </div>
                      </div>
                      <div class="form-group">
                        <select class="form-control border-radius-0" formControlName="course_id">
                          <option value="">Select Course</option>
                          <option [value]="course.id" *ngFor="let course of courses">{{course?.name}}</option>
                        </select>
                        <small *ngIf="submitted && registrationForm.get('course_id').hasError('required')"
                               class="text-danger">
                          Field is required.
                        </small>
                      </div>
                      <div class="checkbox p-0 text-left">
                        <input id="checkbox2" type="checkbox" [(ngModel)]="agreeTermsCondtions" [ngModelOptions]="{standalone: true}">
                        <label for="checkbox2" class="f_12">
                          <small>I agree to terms & conditions</small>
                        </label>
                      </div>
                      <!--<div class="form-row mt-20px">
                        <div class="col-sm-12 text-center">
                          <small class="col-form-label f_12 cursor-pointer text-info">By Clicking Sign Up, you agree to Terms & Privacy Policy</small>
                        </div>
                      </div>-->
                      <div class="form-row mt-20px">
                        <div class="col-sm-12">
                          <button class="btn btn-primary btn-block" type="submit" (click)="register()">Register</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
