import {Injectable} from '@angular/core';

@Injectable()
export class AppConstants {
  static markingSchemes = [
    {'name': 'Percentage', 'value': 'PERCENTAGE'},
    {'name': 'CGPA', 'value': 'CGPA'}
  ];

  static boardUniversity = [
    {'name': 'CBSE', 'value': 'CBSE'},
    {'name': 'ICSE', 'value': 'ICSE'},
    {'name': 'IB', 'value': 'IB'},
    {'name': 'State Board', 'value': 'STATE_BOARD'}
  ];

  static categories = [
    {'name': 'General', 'value': 'GENERAL'},
    {'name': 'OBC', 'value': 'OBC'},
    {'name': 'SC', 'value': 'SC'},
    {'name': 'ST', 'value': 'ST'},
    {'name': 'Others', 'value': 'OTHERS'},
  ];

  static employementStatuses = [
    {'name': 'Employed', 'value': 'EMPLOYED'},
    {'name': 'Un-Employed', 'value': 'UN_EMPLOYED'},
  ];

  static ticketStatuses = [
    {
      'name': 'Pending',
      'value': 'PENDING'
    },
    {
      'name': 'Resoled',
      'value': 'RESOLVED'
    },
    {
      'name': 'Withdrawn',
      'value': 'WITHDRAWN'
    },
    {
      'name': 'To be Taken Later',
      'value': 'TO_BE_TAKEN_LATER'
    }
  ];
  static docFees = [
    {
      'name': 'Marksheet Fee',
      'value': 200
    },
    {
      'name': 'Address Change Fee',
      'value': 500
    },
    {
      'name': 'Consolidated Mark sheet Fee',
      'value': 2000
    },
    {
      'name': 'Duplicate Mark sheet Fee',
      'value': 500
    },
    {
      'name': 'Original Degree Fee',
      'value': 3000
    },
    {
      'name': 'Confidential Result Fee',
      'value': 1000
    },
    {
      'name': 'Document Verification Fee',
      'value':  1000
    },
    {
      'name': 'Transcript Fee',
      'value':   500
    },
    {
      'name': 'Mark sheet Correction Fee',
      'value':  500
    },
    {
      'name': 'Any other document correction Fee',
      'value':  500
    },
    {
      'name': 'Provisional Fee',
      'value':  500
    }

  ];

  static months: any = [
    {
      'name': 'January',
      'value': 1
    },
    {
      'name': 'February',
      'value': 2
    },
    {
      'name': 'March',
      'value': 3
    },
    {
      'name': 'April',
      'value': 4
    },
    {
      'name': 'May',
      'value': 5
    },
    {
      'name': 'June',
      'value': 6
    },
    {
      'name': 'July',
      'value': 7
    },
    {
      'name': 'August',
      'value': 8
    },
    {
      'name': 'September',
      'value': 9
    },
    {
      'name': 'October',
      'value': 10
    },
    {
      'name': 'November',
      'value': 11
    },
    {
      'name': 'December',
      'value': 12
    }
  ];

  static exceptionRoutes = ['tickets', 'response', 'ticket-invoice'];
}
