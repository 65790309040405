<div class="container mt-50px">
  <div class="row p-10">
    <div class="col-1"></div>
    <div class="col-10">
      <div class="card" *ngIf="!showTracker">
        <div class="card-header">
          <div class="row">
            <div class="col-12 text-center">
              <img src="assets/images/logo/logo2.png" alt="Volmint University" style="max-width: 300px">
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row pb-25px text-center">
            <div class="col-12">
              <h4 class="m-t-10 font-weight-bold ">Student Ticket - <span class="span-style">{{issue.name}}</span></h4>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
              <form class="theme-form" [formGroup]="generateTicketForm">
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label pt-0" for="enrollment_id">Enrollment Number</label>
                      <input id="enrollment_id" class="form-control font-weight-bold"
                             formControlName="enrollment_no"
                             readonly="readonly">
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label pt-0" for="email">Email<i class="color-danger">*</i></label>
                      <input id="email" type="email" class="form-control" placeholder="Enter email"
                             formControlName="email">
                      <small *ngIf="submitted && generateTicketForm.get('email').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                      <small
                        *ngIf="submitted && generateTicketForm && generateTicketForm.controls && generateTicketForm.controls['email'] && generateTicketForm.controls['email'].errors && generateTicketForm.controls['email'].errors.pattern"
                        class="text-danger">Invalid email format</small>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label pt-0" for="contact">Contact<i class="color-danger">*</i></label>
                      <input id="contact" type="number" class="form-control" placeholder="Enter Contact Number"
                             formControlName="contact" required>
                      <small *ngIf="submitted && generateTicketForm.get('contact').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                      <small
                        *ngIf="submitted && generateTicketForm && generateTicketForm.controls && generateTicketForm.controls['contact'] && generateTicketForm.controls['contact'].errors && generateTicketForm.controls['contact'].errors.pattern"
                        class="text-danger">Invalid contact format</small>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label pt-0" for="father_name">Father's Name<i
                        class="color-danger">*</i></label>
                      <input id="father_name" class="form-control" placeholder="Enter Father Name"
                             formControlName="father_name" required>
                      <small *ngIf="submitted && generateTicketForm.get('father_name').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label pt-0" for="address">Address<i class="color-danger">*</i></label>
                      <textarea class="form-control resize-none" id="address" type="name" placeholder="Enter Address"
                                rows="5"
                                formControlName="address" required></textarea>
                      <small *ngIf="submitted && generateTicketForm.get('address').hasError('required')"
                             class="text-danger">
                        Field is required.
                      </small>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="col-form-label pt-0" for="remarks">Remarks</label>
                      <textarea class="form-control resize-none font-weight-bold" id="remarks" type="name"
                                placeholder="Enter Remarks" rows="5" formControlName="remarks"
                                readonly="readonly"></textarea>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <div class="row">
                        <div class="col-12">
                          <label class="col-form-label pt-0">Upload Supportive
                            Document</label><i class="color-danger">*</i>
                        </div>
                        <div class="col-12">
                          <button class="btn btn-primary pull-left mt-5px"
                                  (click)="fileUploadSupportiveDocument.click()">
                            <i class="fas fa-upload mr-5px"></i>Upload File
                          </button>
                          <span class="upload-btn-span-view cursor-pointer pull-left mt-10px ml-10px text-info"
                                (click)="goTo(fileUploadSupportiveDocumentData.url)"
                                *ngIf="fileUploadSupportiveDocumentData && fileUploadSupportiveDocumentData.url">View</span>
                          <input class="form-control-file display-hidden"
                                 type="file"
                                 (change)="onFileChange($event, 'SUPPORTIVE_DOCUMENT')"
                                 #fileUploadSupportiveDocument>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div class="card-footer text-center">
          <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12">
              <button class="btn btn-success btn-block" (click)="submit()"
                      *ngIf="(issue && issue.payment_status && issue.payment_status === 'SUCCESS') || issue.amount == 0">
                <span class="f_17">Submit</span>
              </button>
              <button class="btn btn-success btn-block" (click)="payNow()"
                      *ngIf="issue && (!issue.payment_status || issue.payment_status === 'PENDING' || issue.payment_status === 'FAILED') && !submitted && issue.amount != 0">
                <span class="f_17">Pay &#x20b9; {{issue.amount}}</span>
              </button>
              <button class="btn btn-success btn-block"
                      *ngIf="issue && (!issue.payment_status || issue.payment_status === 'PENDING' || issue.payment_status === 'FAILED') && submitted && issue.amount != 0">
                <span class="f_17">
                  <i class="fas fa-spinner fa-pulse mr-1"></i>Pay &#x20b9; {{issue.amount}}
                </span>
              </button>
            </div>
            <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-xs-12"></div>
          </div>
        </div>
      </div>
      <div class="card" *ngIf="showTracker">
        <div class="card-head">
          <div class="row">
            <div class="col-12 text-center">
              <img src="assets/images/logo/logo2.png" alt="Volmint University"
                   style="max-width: 300px; margin-top: 30px">
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="row pb-25px text-center">
            <div class="col-12">
              <h4 class="m-t-10 font-weight-bold">Student Ticket - <span class="span-style">{{issue.name}}</span></h4>
            </div>
          </div>
          <div class="row pb-25px">
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12">
              <h5 class="m-t-10 font-weight-bold text-italic">Ticket Created On : {{issue?.created_at}}</h5>
            </div>
            <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-xs-12 text-right" *ngIf="this.issue['amount'] != 0">
              <h5 class="m-t-10 font-weight-bold text-italic">Payment Received On : {{issue?.payment_date_time}}</h5>
            </div>
          </div>
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12">
              <div class="row">
                <div class="col-12 col-md-12 hh-grayBox pt45 pb20">
                  <div class="row justify-content-between">
                    <div class="order-tracking" [ngClass]="{'completed': (issue.status === 'PAYMENT_RECEIVED' || issue.status === 'OUT_FOR_DELIVERY' || issue.status === 'DELIVERED' || issue.status === 'PAYMENT_NOT_REQUIRED')}">
                      <span class="is-complete"></span>
                      <p class="text-italic" *ngIf="issue && issue.status !== 'PAYMENT_NOT_REQUIRED'">Payment Received</p>
                      <p class="text-italic" *ngIf="issue && issue.status === 'PAYMENT_NOT_REQUIRED'">Request Confrimed</p>
                    </div>
                    <div class="order-tracking" [ngClass]="{'completed': (issue.status === 'OUT_FOR_DELIVERY' || issue.status === 'DELIVERED')}">
                      <span class="is-complete"></span>
                      <p class="text-italic">Document(s) Shipped</p>
                    </div>
                    <div class="order-tracking" [ngClass]="{'completed': (issue.status === 'DELIVERED')}">
                      <span class="is-complete"></span>
                      <p class="text-italic">Document(s) Delivered</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row pb-25px text-center">
            <div class="col-12">
              <h5 class="m-t-10 font-weight-bold text-italic" *ngIf="issue['type'] === 'ADMISSION_ISSUES'">Admission
                Ticket : Status</h5>
              <h5 class="m-t-10 font-weight-bold text-italic" *ngIf="issue['type'] === 'RE_REGISTRATION_ISSUES'">Re -
                Registration Ticket : Status</h5>
              <h5 class="m-t-10 font-weight-bold text-italic" *ngIf="issue['type'] === 'EXAMINATION_ISSUES'">Examination
                Ticket : Status</h5>
              <h5 class="m-t-10 font-weight-bold text-italic" *ngIf="issue['type'] === 'MARKSHEET_ISSUES'">Marksheet
                Ticket : Status</h5>
              <h5 class="m-t-10 font-weight-bold text-italic" *ngIf="issue['type'] === 'TRANSCRIPT_ISSUES'">Transcript
                Ticket : Status</h5>
              <h5 class="m-t-10 font-weight-bold text-italic" *ngIf="issue['type'] === 'REVALUATION_ISSUES'">Revaluation
                Ticket : Status</h5>
              <h5 class="m-t-10 font-weight-bold text-italic" *ngIf="issue['type'] === 'MIGRATION_ISSUES'">Migration
                Ticket : Status</h5>
              <h5 class="m-t-10 font-weight-bold text-italic" *ngIf="issue['type'] === 'ORIGINAL_DEGREE_ISSUES'">
                Original Degree Ticket : Status</h5>
              <h5 class="m-t-10 font-weight-bold text-italic" *ngIf="issue['type'] === 'E_LEARNING_ISSUES'">E - Learning
                Ticket : Status</h5>
              <h5 class="m-t-10 font-weight-bold text-italic" *ngIf="issue['type'] === 'OTHER_ISSUES'">Other Ticket :
                Status</h5>
            </div>
          </div>
        </div>
        <div class="card-footer" *ngIf="issue.amount != 0">
          <div class="row">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center">
              <button class="btn btn-primary" (click)="openUrl(environmentUrl+'issue-payment-receipt/'+unique_id)">
                <i class="fa-solid fa-receipt mr-8px"></i>Download Invoice
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-1"></div>
  </div>
</div>
